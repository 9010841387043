export const filtersTypes = [
  'BusinessPartner',
  'TemplateCategory',
  'Orientation',
  'TemplateType',
  'TemplatePlatform',
  'TemplateNiche',
  'TemplateFeel',
  'TemplateEnergy'
]

export const stmtTypes = ['TemplateAction', 'TemplateUseCase']

// in URL it'll be minDuration, maxDuration
// in query it'll be duration: { min, max}
export const durationFilter = { minDuration: 'min', maxDuration: 'max' }

export const templateListingRefiners = { searchQuery: null, orderBy: null }

export const templateDuration = {
  MIN: 0,
  MAX: 300
}

export const orderByValues = {
  name: ['name_ASC', 'name_DESC'],
  popularity: ['numRenders_ASC', 'numRenders_DESC'],
  date: ['createdAt_ASC', 'createdAt_DESC']
}

export const templateCacheDefaults = {
  minDuration: templateDuration.MIN,
  maxDuration: templateDuration.MAX,
  partnerOnly: false,
  isBasic: false,
  searchQuery: '',
  orderBy: orderByValues.date[1],
  isVideosOnly: null
}

export const reactionStatuses = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
  INDIFFERENT: 'INDIFFERENT'
}

export const pillsDisplayValues = {
  categories: true,
  orientations: true,
  types: true,
  platforms: true,
  niches: true,
  feels: true,
  energies: true,
  minDuration: true,
  maxDuration: true,
  searchQuery: false,
  orderBy: false
}

export const pageSize = {
  templatesPage: 20,
  // templatesPage: 60,
  categoriesPage: 4,
  sliderPage: 5
}

export const queryStringFilters = {
  categories: true,
  orientations: true,
  types: true,
  platforms: true,
  niches: true,
  feels: true,
  energies: true,
  minDuration: true,
  maxDuration: true,
  searchQuery: true,
  orderBy: true
}

import gql from 'graphql-tag'

export const usernameExistsQuery = gql`
  query usernameExists($username: String!) {
    usernameExists(username: $username)
  }
`

export const getLoggedInUser = gql`
  query role {
    me {
      id
      firstName
      lastName
      email
      username
      agency {
        id
        businesses {
          id
          name
        }
      }
      business {
        id
        name
        __typename
      }
      salesAccount {
        id
        logoUrl
        watermarkUrl
        paypalEmail
      }
      plan {
        ... on UserPlanSubscription {
          subscriptionParent {
            name
          }
        }
      }
      extras {
        ... on UserExtraSubscription {
          subscriptionParent {
            name
          }
        }
      }
    }
  }
`

export const registerMutation = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    register(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    ) {
      id
      email
    }
  }
`

export const emailExistsMutation = gql`
  mutation emailExists($email: String!) {
    emailExists(input: { email: $email })
  }
`

export const getSocialPlatforms = gql`
  {
    socialPlatforms {
      name
      endpoint
      icon
    }
  }
`

export const verifyEmailMutation = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      email
      firstName
    }
  }
`

export const loginMutation = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean) {
    login(
      input: { password: $password, email: $email, rememberMe: $rememberMe }
    ) {
      id
      email
    }
  }
`

export const resetPasswordMutation = gql`
  mutation resetPassword(
    $token: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      input: {
        token: $token
        password: $password
        confirmPassword: $confirmPassword
      }
    )
  }
`

export const deleteAccountMutation = gql`
  mutation deleteAccount {
    deleteAccount
  }
`

export const sendPasswordResetLinkMutation = gql`
  mutation sendPasswordResetLink($email: String!) {
    sendPasswordResetLink(email: $email)
  }
`

export const logOutMutation = gql`
  mutation logout {
    logout
  }
`

export const updateBasicUserProfileMutation = gql`
  mutation updateBasicUserProfile(
    $firstName: String!
    $lastName: String!
    $username: String!
    $avatar: String
  ) {
    updateBasicUserProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        avatar: $avatar
      }
    ) {
      firstName
      lastName
      username
      avatar
    }
  }
`

export const changePasswordMutation = gql`
  mutation changePassword(
    $currentPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    changePassword(
      input: {
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmNewPassword: $confirmNewPassword
      }
    )
  }
`

export const uploadAvatarMutation = gql`
  mutation uploadAvatar(
    $fileName: String!
    $contentType: String!
    $isSalesLogo: Boolean
  ) {
    uploadAvatar(
      input: {
        fileName: $fileName
        contentType: $contentType
        isSalesLogo: $isSalesLogo
      }
    ) {
      signedUrl
    }
  }
`

export const resendVerificationTokenMutation = gql`
  mutation resendVerificationToken {
    resendVerificationToken
  }
`
